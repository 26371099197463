import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/common/Layout.jsx';
import SitemapBanner from '../components/sitemap/SitemapBanner.jsx';
import style from './sitemap.module.css';
import { Helmet } from 'react-helmet';

class Sitemap extends React.PureComponent {
    render(){
        return (
            <Layout>
                <Helmet>
                    <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="description" content="Read now for heart information in dogs." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Sitemap - Boehringer Ingelheim" />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content="Read now for heart information in dogs." />
                    <meta property="og:locale" content="en_AU" />

                    <title>Sitemap - Boehringer Ingelheim 2019</title>
                </Helmet>
                <SitemapBanner/>
                <div className="SiteMapMainContainer">
                    <div className="ContentContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionHeading"><Link to="/" className={style.SiteMapLink}><strong>Home</strong></Link></p>
                            </div>
                            <div className="SectionContainer">
                                <ul className="SectionList">
                                    <li className="SectionListElement">
                                        <Link to="/healthy-hearts" className={style.SiteMapLink}><strong>Healthy hearts</strong></Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/healthy-hearts/circulatory-system" className={style.SiteMapLink}>Circulatory system</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/healthy-hearts/heart-facts" className={style.SiteMapLink}>Heart facts</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/healthy-hearts/your-dogs-health" className={style.SiteMapLink}>Your dog's health</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/healthy-hearts/diet" className={style.SiteMapLink}>Diet</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/healthy-hearts/exercise" className={style.SiteMapLink}>Exercise</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/healthy-hearts/routine-check-ups" className={style.SiteMapLink}>Routine check-ups</Link>
                                    </li>
                                </ul>
                            </div>
                            <br /><br /><br />
                            <div className="SectionContainer">
                                <ul className="SectionList">
                                    <li className="SectionListElement">
                                        <Link to="/heart-disease" className={style.SiteMapLink}><strong>Heart disease</strong></Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/heart-disease/what-are-the-signs" className={style.SiteMapLink}>What are the signs</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/heart-disease/understanding-heart-disease" className={style.SiteMapLink}>Understanding heart disease</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/heart-disease/how-is-it-diagnosed" className={style.SiteMapLink}>How is heart disease diagnosed?</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/heart-disease/stages-of-heart-disease" className={style.SiteMapLink}>What are the stages of heart disease?</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/heart-disease/mitral-valve-disease" className={style.SiteMapLink}>Mitral valve disease</Link>
                                    </li>
                                    <li className="SectionListElement">
                                        &mdash; <Link to="/heart-disease/dilated-cardiomyopathy" className={style.SiteMapLink}>Dilated cardiomyopathy</Link>
                                    </li>
                                </ul>
                            </div>
                            <br /><br />
                            <div className="SectionContainer">
                                <p className="SectionHeading"><Link to="/early-diagnosis" className={style.SiteMapLink}><strong>Benefits of an early diagnosis</strong></Link></p>
                            </div>
                            <div className="SectionContainer">
                                <p className="SectionHeading"><Link to="/care-and-treatment" className={style.SiteMapLink}><strong>Care and treatment</strong></Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Sitemap;
