import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import style from './SitemapBanner.module.css';

class SitemapBannerImage extends React.PureComponent {
    render(){
        return (
            <Img
                fluid={this.props.sitemapBannerImage.childImageSharp.fluid}
            />
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                sitemapBannerImage: file(relativePath: { eq: "sitemap/sitemap-banner-image.png" }){
                    childImageSharp {
                        fluid(maxWidth: 1440){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => {
            // let isIE = /*@cc_on!@*/false || !!document.documentMode;            
            let isIE = false;
            
            if( isIE ){
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <SitemapBannerImage sitemapBannerImage={data.sitemapBannerImage} className={style.BannerImage} />
                        </div>
                        <div className={style.HealthyHeartsTitleContainer}>
                            <div className={style.SMTitleTable}>
                                <div className={style.SMTitleTableCell}>
                                    <p className={style.HealthyHeartsTitle}>Sitemap</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Sitemap
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={style.HealthyHeartsBanner}>
                        <div className={style.HealthyHeartsImageContainer}>
                            <SitemapBannerImage sitemapBannerImage={data.sitemapBannerImage} className={style.BannerImage} />
                        </div>
                        <div className={style.HealthyHeartsTitleContainer}>
                            <p className={style.HealthyHeartsTitle}>Sitemap</p>
                        </div>
                        <div className={style.HealthyHeartsBreadcrumbsContainer}>
                            <Link to="/" className={style.HealthyHeartsBreadcrumb}>Home</Link>&nbsp;>&nbsp;
                            Sitemap
                        </div>
                    </div>
                )
            }
        }}
    />
)
